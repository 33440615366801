import React, { useEffect } from "react";
import { Page } from '../../components/page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Button, AlbumList as AlbumListUI, Modal } from "../../ui";
import { useNavigate } from 'react-router-dom';
import { getAllUrlParams } from "../../libs";
import { albumService } from "../../store/services";

export function SmartConnect() {
    const { id } = getAllUrlParams();
    const [loading, setLoading] = React.useState(id ? true : false);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [errorCode, setErrorCode] = React.useState(null);

    const [albums, setAlbums] = React.useState(null);
    const [albumAssociating, setAlbumAssociating] = React.useState(null);

    useEffect(() => {
        if (id) {
            albumService.getFromNFC(id).then((data) => {
                if(data.status) {
                    return data.json();
                }
                if (data.albumId) {
                    setErrorCode(null)
                    navigate(`/album-list`);
                    navigate(`/album?id=${data.albumId}&fromNFC=true`);
                } else {
                    if (data.length === 0) {
                        setErrorCode(5);
                        return;
                    } else {
                        setAlbums(data);
                    }
                }
                return data.json();
            }).then((data) => {
                if (data?.errorCode) {
                    setErrorCode(data.errorCode);
                }
                setLoading(false);
            }).catch((err) => {
                setErrorCode(-1);
                setLoading(false);
            });
        }
    }, [id, navigate, token]);

    const _associateAlbum = (idAlbum) => {
        const album = albums.find((album) => album.id === idAlbum);
        setAlbumAssociating(album);
    }
    const handleChoosen = (idAlbum) => {
        setLoading(true);
        // Associate the album to the NFC
        albumService.putAlbumToNFC(id, idAlbum).then((data) => {
            // if no status no error
            if (!data.status) {
                navigate(`/album-list`);
                navigate(`/album?id=${idAlbum}`);
            }
        }).catch((err) => {
            setErrorCode(-1);
            setLoading(false);
        });
    };

    if (albums) {
        return <Page loading={loading} logoOnTop={true}>
            <div className="p-3 h-100">
                <h3 className="text-center mb-3">Scegli l'album da associare alla foto </h3>
                <AlbumListUI albums={albums} handleAction={_associateAlbum} associate={true} />
            </div>
            <Modal
                title={"Conferma associazione"}
                text={<p>Sei sicuro di voler associare l'album <span className="fw-bold">{albumAssociating?.name}</span> alla smartphoto? L'operazione è irreversibile.</p>}
                primaryText="Conferma"
                secondaryText="Annulla"
                handleConfirm={() => handleChoosen(albumAssociating.id)}
                id="confirmAssociate"
                primaryColor="btn-primary"
            />
        </Page>
    }

    if (!token && id) {
        return <Page logoOnTop={true}>
            <div className="p-3 d-flex justify-content-center align-items-center flex-column h-100">
                <h3 className="text-center text-danger fw-bold mb-3">Esegui il login per visualizzare l'album</h3>
                <Button onClick={() => navigate('/login')}>Val al Login</Button>
            </div>
        </Page>
    }

    return (
        <Page loading={loading} logoOnTop={true}>
            <div className="p-3 d-flex justify-content-center align-items-center flex-column h-100">
                <h3 className="text-center mb-5">
                    Abilita l'NFC e avvicina il dispositivo alla tua smartphoto acquistata su Kangury
                </h3>
                <FontAwesomeIcon icon={`fa-solid fa-nfc-symbol`} size='5x' className="mb-5" />
                {errorCode && <p className="text-danger text-center fw-bold">Errore: {traslateErrorCode(errorCode)}</p>}
                <h5 className="mb-2">
                    Problemi?
                </h5>
                <div className="w-100">
                    <Accordion items={helps} />
                </div>
            </div>
        </Page>
    );
}

const traslateErrorCode = (errorCode) => {
    switch (errorCode) {
        case 1:
            return "Dispositivo NFC non trovato nel database, riprovare o contattare l'assistenza";
        case 2:
            return "NFC già aggiunto ma album associato non trovato, riprovare o contattare l'assistenza";
        case 3:
            return "Accesso negato, NFC associato ad un altra persona";
        case 4:
            return "4, contattare l'assistenza";
        case 5:
            return "Nessun album valido trovato, contattare l'assistenza";
        default:
            return "Server non raggiungibile, controllare la connessione o riprovare più tardi";
    }
}

const helps = [
    {
        id: 1,
        question: "Come posso abilitare l'NFC?",
        answer: "Per abilitare l'NFC sul tuo dispositivo, vai nelle impostazioni del tuo smartphone e cerca la voce NFC. Una volta trovata, attivala. Se non la trovi cerca su google inserendo il modello del tuo smartphone."
    },
    {
        id: 2,
        question: "Come posso leggere la smartphoto?",
        answer: "Per leggere la smartphoto, avvicina il dispositivo alla smartphoto acquistata su Kangury. Se il dispositivo non legge la smartphoto, assicurati che l'NFC sia attivo."
    },
    {
        id: 3,
        question: "Cosa posso fare se il dispositivo non legge la smartphoto?",
        answer: "Se il dispositivo non legge la smartphoto, assicurati che l'NFC sia attivo. Se il problema persiste, contatta il nostro servizio clienti."
    }
]