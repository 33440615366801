import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from '../../ui';

export const SortAlbum = ({ setAlbums, albums }) => {
    const [sortValue, setSortValue] = React.useState('1');
    const [orderSortValue, setOrderSortValue] = React.useState('1');

    const genericSort = (sortValue, orderSortValue) => {
        switch (sortValue) {
            case '1':
                return orderSortValue === '1' ? (a, b) => new Date(b.date_creation) - new Date(a.date_creation) : (a, b) => new Date(a.date_creation) - new Date(b.date_creation);
            case '2':
                return orderSortValue === '1' ? (a, b) => a.name.localeCompare(b.name) : (a, b) => b.name.localeCompare(a.name);
            case '3':
                return orderSortValue === '1' ? (a, b) => b.countFiles - a.countFiles : (a, b) => a.countFiles - b.countFiles;
            case '4':
                return orderSortValue === '1' ? (a, b) => b.memoryUsed - a.memoryUsed : (a, b) => a.memoryUsed - b.memoryUsed;
            default:
                return () => { };
        }
    };

    const handleSortChange = (event) => {
        const sortValue = event.target.value;
        setSortValue(sortValue);
        const sortedAlbums = [...albums].sort(genericSort(sortValue, orderSortValue));
        setAlbums(sortedAlbums);
    };

    const handleChangeOrderSortValue = () => {
        const nextOrderValue = orderSortValue === '1' ? '2' : '1';
        setOrderSortValue(nextOrderValue);
        const sortedAlbums = [...albums].sort(genericSort(sortValue, nextOrderValue));
        setAlbums(sortedAlbums);
    };

    return (
        <div className="d-flex justify-content-end">

            <select className="form-select mb-4" aria-label="Default select example" onChange={handleSortChange}>
                <option value="1">Data creazione</option>
                <option value="2">Nome</option>
                <option value="3">Numero Elementi</option>
                <option value="4">Memoria Occupata</option>
            </select>
            <button className="btn btn-primary h-100 ms-2" type="button" onClick={handleChangeOrderSortValue}>
                {orderSortValue === '1' ? <FontAwesomeIcon icon="fa-regular fa-sort-down" /> : <FontAwesomeIcon icon="fa-regular fa-sort-up" />}
            </button>
        </div>
    );
};