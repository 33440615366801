import React from 'react';
import { Page } from '../../components';

export function ConsumerProtection() {
    return (
        <Page title={'Tutela del Consumatore'}
            visibleBottomNavigation={false}>
            <div className='container d-flex align-items-start justify-content-center'>
                <div className='card p-4 shadow-lg mb-4 mt-4'>
                    <h1 className='text-center mb-4'>Tutela del Consumatore</h1>
                    <p>
                        In Kangury, l'obiettivo primario è il pieno soddisfacimento delle esigenze di ciascun cliente.
                    </p>
                    <h2>Sicurezza delle Transazioni Economiche</h2>
                    <p>È possibile effettuare i pagamenti tramite il proprio account Paypal o usando una carta di credito</p>
                    <p>In entrambi i casi il pagamento è gestito da Paypal e, per questo, avviene in condizioni di totale sicurezza</p>

                    <h2>Garanzia dei Prodotti</h2>

                    <p>Per la produzione e spedizione dei propri prodotti Kangury si affida all'azienda partner Digitalpix</p>

                    <p>Digitalpix garantisce l'integrità materiale delle foto al momento della consegna. Nel caso in cui le foto consegnate al Cliente siano visibilmente danneggiate o presentino dei difetti, sarà cura del fornitore provvedere a proprie spese alla sostituzione. Rimane escluso il diritto del Cliente al risarcimento di qualsivoglia danno.</p>

                    <p>Eventuali difetti dovranno essere denunciati dal Cliente, a pena di decadenza, entro e non oltre il termine di 14 giorni dalla data di consegna, inviando una e-mail all'indirizzo <a href="mailto:servizioclienti@digitalpix.it">servizioclienti@digitalpix.it</a>. Il fornitore si impegna a rispondere ad ogni reclamo motivato e non anonimo entro 48 ore.</p>

                    <p>Per problemi con Digitalpix contattaci a questa email: <a href="mailto:support@kangury.it">support@kangury.it</a></p>

                    <h2>Diritto di Recesso</h2>

                    <h4>Cancellazione dell'ordine</h4>
                    <p>La cancellazione di un ordine può essere effettuata dal Cliente con il pulsante “Annulla Ordine” e la successiva richiesta di conferma annullamento.</p>
                    <p>Questa funzione si disattiva invece immediatamente dopo aver effettuato il pagamento.</p>
                    <p>In ogni caso, ogni ordine confermato può essere annullato mediante richiesta via email, all'indirizzo <a href="mailto:support@kangury.it">support@kangury.it</a>, entro 15 minuti dalla conferma. Non saranno considerate richieste di annullamento dell'ordine pervenute oltre i tempi di cui sopra.</p>

                    <h4>Esercizio del diritto di recesso</h4>
                    <p>Ai sensi del DECRETO LEGISLATIVO 21 febbraio 2014, n. 21- Attuazione della direttiva 2011/83/UE sui diritti dei consumatori, recante modifica delle direttive 93/13/CEE e 1999/44/CE e che abroga le direttive 85/577/CEE e 97/7/CE. (14G00033) - (GU Serie Generale n.58 del 11-3-2014), il Consumatore ha diritto di recedere dall'acquisto del/i prodotto/i o servizio/i entro il termine di 14 giorni lavorativi dal ricevimento della merce, per i prodotti, o dalla conclusione del contratto, per i servizi, inviando al fornitore una comunicazione mediante email all'indirizzo <a href="mailto:support@kangury.it">support@kangury.it</a> contenente:
                        <ul>
                            <li>la manifestazione di volontà di recedere dal contratto</li>
                            <li>la descrizione del prodotto acquistato</li>
                            <li>la data in cui è stato effettuato l'ordine</li>
                            <li>gli estremi del proprio c/c bancario (Codice IBAN) al fine di consentire al fornitore il rimborso del prezzo di acquisto</li>
                        </ul>
                    </p>

                    <h4>Esclusione del diritto di recesso</h4>
                    <p>Ai sensi dell’art. 59 comma 1 lett. a) e c) del Codice del Consumo, D.Lgs. 206/2005 successive modifiche e integrazioni, è escluso il diritto di recesso per i beni la cui completa esecuzione del servizio è immediata e sono realizzati e personalizzati sulla base delle prescrizioni dell’Utente.</p>

                    <h4>Rimborso del corrispettivo</h4>
                    <p>
                        Per ottenere il rimborso del corrispettivo pagato il Consumatore, entro il termine di 30 giorni dalla data di ricevimento del bene, dovrà rispedirlo al fornitore a mezzo di spedizione rintracciabile.
                    </p>
                    <p>
                        Entro 30 giorni dalla data di ricezione della comunicazione nella quale il consumatore esprime la volontà di esercitare il diritto di recesso, il fornitore provvederà a rimborsare al consumatore il prezzo pagato per l'acquisto del bene mediante bonifico bancario al netto delle spese bancarie a lui addebitate dalla banca pari a € 2,95. Il rimborso si riferisce al solo prezzo del bene, come evidenziato nella nota d'ordine e nel documento di trasporto, al netto delle spese di spedizione e di consegna. Sono in ogni caso a carico del consumatore le spese dirette di restituzione del bene al mittente.
                    </p>

                    <h2>Diritti d'Autore</h2>
                    <p>Il Cliente è l'unico responsabile del rispetto dei diritti d'autore riguardanti le immagini inviate a questo servizio.</p>
                    <p>Stessa cosa è valida per i file che l'utente caricherà nei propri album digitali. L'utente avrà anche il diritto di scegliere se eliminare tali file o con chi condividerli.</p>

                    <h2>Controversie</h2>
                    <p>
                        Per le controversie civili inerenti alla applicazione del medesimo (decreto in attuazione della direttiva 97/7/CE relativa alla protezione dei consumatori in materia di contratti a distanza) la competenza territoriale inderogabile è del giudice del luogo di residenza o domicilio del consumatore, se ubicati nel territorio dello Stato. Il fornitore aderisce altresì alla composizione delle controversie proposta dal sistema nazionale delle Camere di Commercio, Industria, Artigianato e Agricoltura, denominata RisolviOnline. RisolviOnline consente di risolvere le liti commerciali legate ad Internet ed al commercio elettronico in modo semplice, veloce ed economico. Il Cliente può contattare direttamente la Camera Arbitrale per:
                        <ul>
                            <li>inoltrare la domanda di conciliazione online</li>
                            <li>aderire al tentativo di conciliazione</li>
                        </ul>
                    </p>

                    <h2>Responsabilità Sociale</h2>
                    <h4>Tutela dei minori</h4>
                    <p>
                        Il fornitore si astiene dall'accettare consapevolmente ordini di beni e servizi da parte di minori, dal raccoglierne dati, dall'istigarli a partecipare o a divulgare dati con prospettiva di giochi o simili attività
                    </p>
                </div>
            </div>
        </Page>
    )
}