import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { userService } from '../../store/services';
import { Button, PasswordField, ToastDanger } from '../../ui';

export function Login() {
    const navigate = useNavigate();
    const [showToast, setShowToast] = React.useState(false);
    const [data, setData] = React.useState({
        email: '',
        password: '',
    });

    const handleLogin = useCallback(() => {
        if (data.email && data.password) {
            const emailLowerCaseWithoutSpace = data.email.toLowerCase().replace(/\s/g, '');
            userService.login(emailLowerCaseWithoutSpace, data.password)
                .then(data => {
                    if (data.status === 401) {
                        setShowToast(true);
                        return;
                    }
                    if (data.access) {
                        localStorage.setItem('token', data.access);
                        localStorage.setItem('data', JSON.stringify(data));
                        localStorage.setItem('email', emailLowerCaseWithoutSpace);
                        localStorage.setItem('isAdmin', data.isAdmin);
                        window.history.back();
                    } else
                        setShowToast(true);
                })
                .catch(() => {
                    setShowToast(true);
                });
        }
    }, [data]);

    useEffect(() => {
        // add enter listener to login
        const handleEnter = (e) => {
            if (e.key === 'Enter') {
                handleLogin();
            }
        }
        window.addEventListener('keydown', handleEnter);
        return () => {
            window.removeEventListener('keydown', handleEnter);
        }
    }, [handleLogin]);

    /* const handleSocialLogin = (social) => {
        if (window.ReactNativeWebView) {
            const message = {
                type: ''
            }
            if (social === 'facebook')
                message.type = 'facebookLoginClicked';
            if (social === 'google')
                message.type = 'googleLoginClicked';
            if (social === 'apple')
                message.type = 'appleLoginClicked';
            window.ReactNativeWebView.postMessage(JSON.stringify(message));
        }
    } */

    return (
        <Page visibleBottomNavigation={false}
            title={"Login"}>
            <div className='container p-3'>
                {showToast && <ToastDanger>
                    Login non riuscito, riprova
                </ToastDanger>}
                <input type="text" placeholder="Email" className='w-100 form-control rounded-5 mt-3' value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                <PasswordField value={data.password} setValue={(value) => setData({ ...data, password: value })} />
                <Button onClick={handleLogin} otherClass={"mt-3 p-1"}>
                    Accedi
                </Button>
                <button type='button' className={"btn btn-link w-100 mt-3 text-decoration-underline"} onClick={() => navigate('/forgotten-password')}>
                    Hai dimenticato la password?
                </button>


                {/* <div className='w-100 position-relative mt-3'>
                    <div className='position-absolute horizontal-line' />
                </div>

                <Button onClick={() => handleSocialLogin("google")} otherClass={"mt-3 p-1 btn-secondary"}>
                    Accedi con Google
                </Button>
                <Button onClick={() => handleSocialLogin("facebook")} otherClass={"mt-1 p-1 btn-secondary"}>
                    Accedi con Facebook
                </Button>
                {
                    // check if we are in safari
                    !navigator.userAgent.match(/(Android)/) &&
                    <Button onClick={() => handleSocialLogin("apple")} otherClass={"mt-1 p-1 btn-secondary"}>
                        Accedi con Apple
                    </Button>
                } */}

                <div className='w-100 position-relative mt-3'>
                    <div className='position-absolute horizontal-line' />
                </div>

                <div className='mt-5 w-100 fs-5 text-center fw-bold'>
                    Sei un nuovo cliente?
                </div>
                <button type="button" className={`btn rounded-5 w-100 fs-6 mt-1 p-1 btn-secondary`} onClick={() => navigate('/registration')}>
                    Registrati
                </button>
            </div>
        </Page>
    );
}