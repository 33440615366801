import React from 'react'
import { Button } from '..'

export function CardGift({ handleAccept, handleReject, title, sender, creationDate }) {

    return (
        <div className="card p-3 shadow">
            <div className="row g-0 fw-bold">
                {title}
            </div>
            <div className="card-body">
                <p className="m-0 fw-bold">Chi ti manda il regalo?</p>
                <p className="m-0 mb-1">{sender}</p>
                <p className="m-0 fw-bold">
                    Data invio regalo
                </p>
                <p className="m-0">
                    {creationDate}
                </p>
                <div className="row g-0 mt-2 justify-content-between">
                    <div className="col-5">
                        <Button otherClass={"p-0 btn-danger"} onClick={handleReject}>
                            Rifiuta
                        </Button>
                    </div>
                    <div className="col-5">

                        <Button otherClass={"p-0"} onClick={handleAccept}>
                            Accetta
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    );
}