import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FixedBottom } from '../../ui';
import Logo from '../../ui/assets/logo.png';

const menuItems = [
    {
        label: 'Profilo',
        path: '/profile',
        icon: 'fa-user',
    },
    {
        label: 'Home',
        path: '/',
        icon: 'fa-shop'
    },
    {
        label: 'Smart Conmnect',
        path: '/smart-connect',
        icon: 'fa-wifi'
    },
    {
        label: 'Smart Photo',
        path: '/album-list',
        icon: 'fa-folders'
    },
    {
        label: 'Carrello',
        path: '/cart',
        icon: 'fa-shopping-cart'
    }
];

export function BottomNavigation(props) {
    const page = window.location.pathname;
    const navigate = useNavigate();
    const lockButtonsRef = React.useRef(page === '/cart');

    React.useEffect(() => {
        if(lockButtonsRef.current) {
            // disable navigation buttons for 1 second if in cart to avoid double click
            setTimeout(() => {
                lockButtonsRef.current = false;
            }, 500);
        }
    }, [page]);

    const handleCardClick = (path) => {
        if(lockButtonsRef.current) return;
        navigate(path);
    }

    return (
        <FixedBottom>
            <div className="row w-100">
                {menuItems.map((item) => (
                    item.path === '/smart-connect' ?
                        <div className="col position-relative" key={item.label}>
                            <button className={`btn btn btn-primary text-capitalize rounded-circle position-absolute start-50 m-0 translate-middle-x bottom-0 bg-tertiary${page === item.path ? " text-primary" : " text-white z-1000"}`} key={item.label} onClick={() => handleCardClick(item.path)} style={{ width: '80px', height: '80px' }}>
                                <img src={Logo} alt="logo" className='w-100' />
                            </button>
                        </div>
                        :
                        <button className={`col btn btn-link text-capitalize m-0${page === item.path ? " text-primary" : " text-dark"}`} key={item.label} onClick={() => handleCardClick(item.path)}>
                            <FontAwesomeIcon icon={`fa-regular ${item.icon}`} size='2x' />
                        </button>
                )
                )}
            </div>
        </FixedBottom>
    );
}