import ReactGA from 'react-ga4';
/**
 * General documentation about Google Analytics:
ReactGA.send e ReactGA.event sono due funzioni fornite dalla libreria react-ga per inviare dati a Google Analytics.

ReactGA.send(fieldsObject): Questa funzione è più generica e può essere utilizzata per inviare diversi tipi di hit a Google Analytics. Il parametro fieldsObject è un oggetto che specifica i dettagli del hit. Deve includere un campo hitType che specifica il tipo di hit da inviare (ad esempio, 'pageview', 'event', 'social', 'timing', ecc.). Può includere anche altri campi in base al tipo di hit.

ReactGA.event(args): Questa funzione è specifica per l'invio di eventi a Google Analytics. Il parametro args è un oggetto che specifica i dettagli dell'evento. Deve includere i campi category e action, e può includere anche i campi label e value.
 */

/**
Il campo hitType nella funzione ReactGA.send specifica il tipo di hit (ovvero, evento) che si desidera inviare a Google Analytics. Questo campo è obbligatorio e può assumere diversi valori, tra cui:

'pageview': Questo hitType viene utilizzato per tracciare una visualizzazione di pagina. Quando si invia un hit di tipo 'pageview', Google Analytics incrementa il conteggio delle visualizzazioni di pagina.

'event': Questo hitType viene utilizzato per tracciare un evento specifico, come un click su un pulsante o un invio di form. Quando si invia un hit di tipo 'event', è possibile specificare ulteriori dettagli sull'evento utilizzando i campi 'eventCategory', 'eventAction', 'eventLabel' e 'eventValue'.

'social': Questo hitType viene utilizzato per tracciare interazioni sociali, come un "Mi piace" o un "Condividi" su un social network.

'timing': Questo hitType viene utilizzato per tracciare il tempo impiegato per completare un'azione specifica, come il caricamento di una pagina o il download di un file.
 * @param {*} hitType 
 * @param {*} page 
 * @param {*} title 
 */
function sendToAnalytics(hitType, page, title, eventCategory) {
    console.log('sendToAnalytics', { hitType, page, title, eventCategory });
    if (window.location.origin.indexOf("localhost") >= 0) {
        return;
    }
    console.log('not localhost')
    if (initialize())
        ReactGA.send({ hitType, page, title, eventCategory });
}

/**
 * action: string;
 * category: string;
 * label?: string;
 * value?: number;
 * nonInteraction?: boolean;
 * transport?: ('beacon' | 'xhr' | 'image');
 */
function sendEventToAnalytics(data) {
    console.log('sendEventToAnalytics', data);
    if (window.location.origin.indexOf("localhost") >= 0) {
        return;
    }
    console.log('not localhost')
    if (initialize())
        ReactGA.event(data);
}

function gtag(...data) {
    console.log('gtag', data);
    if (window.location.origin.indexOf("localhost") >= 0) {
        return;
    }
    console.log('not localhost')
    if (initialize())
        ReactGA.gtag(...data);
}

function initialize() {
    if (!ReactGA.gaInitialized) {
        console.log('initialize')
        const gaAutorized = localStorage.getItem('gaAutorized');
        if (gaAutorized === 'true') {
            allConsentGranted();
            sendIosDownloadEvent();
        } else {
            // setDefaultConsent();
            return false;
        }
        ReactGA.initialize('G-2QL50Y92KL');
    }
    return true;
}

function allConsentGranted() {
    ReactGA.gtag('consent', 'update', {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted'
    });
}

function sendIosDownloadEvent() {
    const downloadEventTriggered = localStorage.getItem('downloadEventTriggered');
    // check if the user is on iOS
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && !downloadEventTriggered) {
        // check if the app is running in standalone mode
        ReactGA.gtag('event', 'conversion', {'send_to': 'AW-1017317437/D6KuCPGg7r0ZEL2QjOUD'});
        localStorage.setItem('downloadEventTriggered', 'true');
    }
}
export function initializeGoogleAnalytics() {
    if (!(window.location.origin.indexOf("localhost") >= 0)) {
        initialize();
    }

    window.ga = {
        send: sendToAnalytics,
        event: sendEventToAnalytics,
        gtag: gtag
    }

    ReactGA.event({
        category: 'Main',
        action: 'Application started',
    });
}
