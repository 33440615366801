import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BottomNavigation, TopNavigation } from '..';
import { useNavigate } from 'react-router-dom';
import { cartActions, mainDataActions } from '../../store/actions';
import { LoadingPage, GAPermission } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ToastDanger } from '../../ui';
import logo from '../../ui/assets/logo.png';

const pagesWithScrollTop = ['/product', '/'];

export function Page({ children, visibleBottomNavigation = true, customTopSection = null, customBottomSection = null, title = null, loading = false, simpleTitle = false, scrollButtons = false, hideContainer = false, logoOnTop = false }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isReady } = useSelector(state => state.mainData);
    const cartIsReady = useSelector(state => state.cartData).isReady;
    const toastText = useSelector(state => state.alertData.toastText);
    const scrollRef = React.useRef(null);
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        if (!isReady)
            dispatch(mainDataActions.get());

    }, [dispatch, isReady]);

    React.useEffect(() => {
        if (!cartIsReady)
            dispatch(cartActions.get());

    }, [cartIsReady, dispatch]);

    React.useEffect(() => {
        // remove element with these classes if present: "modal-backdrop fade show"
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop)
            backdrop.remove();
    }, []);

    React.useEffect(() => {
        const previousPage = localStorage.getItem('previousPage');
        const actualPage = window.location.pathname;
        if (!previousPage) {
            localStorage.setItem('previousPage', actualPage);
        } else {
            if (actualPage === '/upload-order' && previousPage !== '/complete-order') {
                localStorage.setItem('previousPage', '/ordersBack');
                navigate('/orders');
            }
            else if (actualPage === '/pay' && previousPage === '/ordersBack') {
                navigate('/profile');
            }
            else if (actualPage !== '/warn-exit-product')
                localStorage.setItem('previousPage', actualPage);
        }
    }, [navigate]);

    React.useLayoutEffect(() => {
        const ref = scrollRef.current;
        const saveScrollTop = () => {
            window.scrollTopSaved = ref.scrollTop;
        };
        if (ref) {
            if (pagesWithScrollTop.includes(window.location.pathname)) {
                ref.scrollTop = window.scrollTopSaved;
                ref.addEventListener('scroll', () => {
                    saveScrollTop();
                });
            }
        }


        return () => {
            if (ref)
                ref.removeEventListener('scroll', saveScrollTop);
        }
    }, []);


    React.useEffect(() => {
        const showAlert = () => {
            window.alert('Connessione internet persa, le immagini caricate in precedenza potrebbero non essere visibili');
        };
        window.addEventListener('offline', showAlert);
        return () => {
            window.removeEventListener('offline', showAlert);
        };
    }, []);

    const [isScrollButtonsVisible, setIsScrollButtonsVisible] = React.useState(false);
    const hideTimerRef = React.useRef(null);
    const scrollToStart = () => {
        // scroll smooth
        const ref = scrollRef.current;
        if (ref)
            ref.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
    };
    const scrollToBottom = () => {
        const ref = scrollRef.current;
        if (ref)
            ref.scrollTo({
                top: ref.scrollHeight,
                behavior: 'smooth'
            });
    };
    const showScrollButtons = () => {
        setIsScrollButtonsVisible(true);
        if (hideTimerRef.current)
            clearTimeout(hideTimerRef.current);
        hideTimerRef.current = setTimeout(() => {
            setIsScrollButtonsVisible(false);
        }, 2000);
    };

    React.useEffect(() => {
        setShow(true);
        return () => {
            setShow(false);
        };
    }, []);

    const sendHeartbeat = () => {
        if (window.ReactNativeWebView) {
            const message = {
                type: 'heartbeat'
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(message));
        }
    }

    return (
        <div className='position-relative w-100 h-100' onTouchEnd={sendHeartbeat}>
            <div className={`w-100 h-100 position-relative ps-0 pe-0 d-flex flex-column fade ${show ? 'show' : ''} ${hideContainer ? '' : 'container'}`}>
                <div className=''>
                    {title && <TopNavigation title={title} simpleTitle={simpleTitle} />}
                    {logoOnTop && <div className='p-1 d-flex align-items-center bg-tertiary  justify-content-center' style={{ height: '50px' }}>
                        <img src={logo} alt="logo" className='h-100 me-2' />
                    </div>}
                    {customTopSection}

                </div>
                <div className='overflow-y-auto overflow-x-hidden flex-grow-1 w-100' ref={scrollRef} onScroll={showScrollButtons} id='page'>
                    {isScrollButtonsVisible && scrollButtons && <div className='position-fixed top-5 end-0 me-2 w-0 z-1000 opacity-75'>
                        <Button onClick={scrollToStart}>
                            <FontAwesomeIcon
                                icon="fa-regular fa-arrow-up"
                                className="fs-5"
                            />
                        </Button>
                    </div>}
                    {children}
                    {isScrollButtonsVisible && scrollButtons && <div className='position-fixed bottom-10 end-0 me-2 w-0 z-1000 opacity-75'>
                        <Button onClick={scrollToBottom}>
                            <FontAwesomeIcon
                                icon="fa-regular fa-arrow-down"
                                className="fs-5"
                            />
                        </Button>
                    </div>}
                </div>
                <div>
                    {visibleBottomNavigation && (customBottomSection || <BottomNavigation />)}
                </div>
            </div>
            <LoadingPage show={!isReady || loading} />
            {toastText && <ToastDanger warningImageFormat={true}>
                {toastText}
            </ToastDanger>}
            {/* <InstallToast /> */}
            <GAPermission />
        </div>
    );
}