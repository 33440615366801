export const common = {
  fetchAPI,
  refreshToken,
  header: _header,
};
const version = require('../../../package.json').version;
const userAgent = window.navigator.userAgent;
function _header(formData, token = false, useStringify) {
  let headers = {
    "version": version,
    "user-agent": userAgent,
  };
  if (useStringify) {
    headers = { ...headers, "Content-Type": "application/json", Accept: "application/json" };
  }
  if (token) {
    const stringToken = localStorage.getItem("token");
    headers = { ...headers, Authorization: `Bearer ${stringToken}` };
  }
  if (!formData)
    return headers;
  return { ...headers, ...formData };
}

const _callAPI = async ({
  url,
  parameters = "",
  method,
  data = null,
  useStringify = true,
  formData = null,
  token = false,
  signal = null,
  jsonResponse = true,
  imgResponse = false,
  useDigitalPixAPI = false,
}) => {
  const gpxFile = data;
  if (!localStorage.getItem("token") && token) {
    return Promise.reject({ message: "No token" });
  }
  const originAPI = useDigitalPixAPI ? process.env.REACT_APP_DIGITALPIX_API_URL : process.env.REACT_APP_API_URL;
  return fetch(originAPI + url + parameters, {
    method: method,
    headers: common.header(formData, token, useStringify),
    body: useStringify && data ? JSON.stringify(data) : gpxFile,
    signal,
  })
    .then((data) => {
      if ((data.status === 200 || data.status === 201) && jsonResponse) return data.json();
      if (imgResponse) {
        // translate from byte string to blob
        return data.blob();
      }
      return data;
    })
    .then((data) => {
      if (data.status === 401) {
        localStorage.removeItem("data");
        localStorage.removeItem("token");
        return;
      }
      return data;
    })
    .catch((error) => {
      return error;
    });
}

const _tokenIsValid = () => {
  const data = JSON.parse(localStorage.getItem("data"));
  if (!data) return false;
  const expirationDate = new Date(data.tokenExpiration);
  if (expirationDate <= new Date()) {
    return false;
  }
  return true;
};

const _refreshTokenIsValid = () => {
  const data = JSON.parse(localStorage.getItem("data"));
  const expirationDate = new Date(data?.refreshTokenExpiration);
  if (expirationDate <= new Date()) {
    return false;
  }
  return true;
}

async function refreshToken() {
  const dataToken = JSON.parse(localStorage.getItem("data"));
  if (!dataToken) return;
  const url = `User/token/refresh`;
  const data = {
    refresh: dataToken.refresh
  }
  return common.fetchAPI({ url, method: "POST", data })
    .then((data) => {
      if (data.status === 401) {
        localStorage.removeItem("data");
        localStorage.removeItem("token");
        return;
      }
      if (data.access)
        localStorage.setItem('token', data.access);
      const localData = JSON.parse(localStorage.getItem('data'));
      localData.tokenExpiration = new Date(data.tokenExpiration);
      localData.refreshTokenExpiration = new Date(data.refreshTokenExpiration);
      localData.access = data.access;
      localStorage.setItem('data', JSON.stringify(localData));
    })
    .catch((error) => {
    });
}

async function fetchAPI(data) {
  if (!data.token || _tokenIsValid()) return _callAPI(data);
  if (_refreshTokenIsValid()) {
    await refreshToken();
    return _callAPI(data);
  }
  localStorage.removeItem("data");
  localStorage.removeItem("token");
  localStorage.removeItem("isAdmin");
  localStorage.removeItem("email");
  return Promise.reject({ message: "Token expired" });
}
