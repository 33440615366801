import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { userService } from '../../store/services';
import { Button } from '../../ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Profile({ version }) {
    const { general } = useSelector(state => state.mainData);
    const [isLogged, setIsLogged] = React.useState(localStorage.getItem('token') ? true : false);
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem('isAdmin') === 'true';
    const email = localStorage.getItem('email');

    const handleNavigation = (url) => {
        navigate(url);
    }

    const handleLogout = () => {
        userService.logout().then((data) => {
            setIsLogged(false);
        });
    }

    return (
        <Page logoOnTop={true}>
            <div className='pb-5'>
                {!isLogged && <div className='p-3'>
                    <Button onClick={() => handleNavigation('/login')} otherClass={"btn-primary mt-3"}>
                        Accedi/Registrati
                        <span className='ms-2 p-1'></span>
                    </Button>
                </div>}
                {isLogged && <div className='p-3 pb-0'>
                    <div className='text-center mb-2 fst-italic'>
                        <span className='fw-bold'> Ciao!</span> Sei loggato con l'email <span className='fw-bold'>{email}</span>
                    </div>
                    <div className='fw-bold'>Gestione Profilo</div>
                    <ul className="list-group list-group-flush">
                        <button className="list-group-item text-start" onClick={() => handleNavigation('/orders')}>
                            Elenco ordini
                        </button>
                        <button className="list-group-item text-start" onClick={() => handleNavigation('/manage-plan')}>
                            Gestione Piano ⭐
                        </button>
                        <button className="list-group-item text-start" onClick={() => handleNavigation('/shipping-addresses')}>
                            Gestisci indirizzi di spedizione
                        </button>
                        <button className="list-group-item text-start" onClick={() => handleNavigation('/billing-addresses')}>
                            Gestisci indirizzi di fatturazione
                        </button>
                        <button className="list-group-item text-start" onClick={() => handleNavigation('/discount-code-list')}>
                            Codici sconto disponibili
                        </button>
                        <button className="list-group-item text-start"
                            onClick={() => handleNavigation('/profile-remove')}>
                            Elimina utente
                        </button>
                        <button className="list-group-item text-primary fw-bold text-start" onClick={handleLogout}>
                            Esci
                        </button>
                        <button className="list-group-item"></button>
                    </ul>
                </div>}
                <div className='p-3'>
                    <div className='fw-bold'>Informazioni</div>
                    <ul className="list-group list-group-flush">
                        <button className="list-group-item text-start"
                            onClick={() => handleNavigation(`/install-guide`)}>
                            Installa Kangury
                            <FontAwesomeIcon icon="fa-regular fa-download" className="ms-2" />
                        </button>
                        <button className="list-group-item text-start"
                            onClick={() => handleNavigation(`/shipping-costs`)}>
                            Spese di spedizione
                        </button>
                        <button className="list-group-item text-start"
                            onClick={() => handleNavigation(`/privacy-policy`)}>
                            Privacy Policy
                        </button>
                        <button className="list-group-item text-start"
                            onClick={() => handleNavigation(`/consumer-protection`)}>
                            Tutela del Consumatore
                        </button>
                        <button className="list-group-item text-start"
                            onClick={() => handleNavigation(`/assistance`)}>
                            Assistenza
                        </button>
                        <button className="list-group-item text-center">
                            <div>Kangury v.{version}</div>
                            <div>Catalog v.{process.env.REACT_APP_VERSION_API}-{general?.version}</div>
                        </button>
                    </ul>
                </div>

                {isAdmin && <div className='p-3'>
                    <div className='fw-bold'>Funzionalità Amministratore</div>
                    <ul className="list-group list-group-flush">
                        <button className="list-group-item text-start"
                            onClick={() => handleNavigation('/admin-panel')}>
                            Pannello amministratore
                        </button>
                    </ul>
                </div>}
            </div>
        </Page>
    );
}