import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export function CardDiscounts({ rows, link }) {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(link);
    }

    return (
        <div className={`card rounded-4 overflow-hidden shadow m-3`}>
            <div className="card-body">
                <div className='w-100 text-center mb-3 position-relative'>
                    <FontAwesomeIcon icon="fa-regular fa-tags" size="2x" />
                </div>
                {
                    rows?.map((row, index) => (
                        <div className={`${row.notApplicable ? 'bg-warning' : ''}`} key={index}>	
                            <p className="card-title fs-6 fw-bold mb-1">
                                <FontAwesomeIcon icon="fa-regular fa-tag" className='me-2' />
                                {row.title}
                            </p>
                            <p className={`card-text fs-6 m-0 ${row.notApplicable ? 'text-danger' : ''}`}>
                                {row.text}
                            </p>
                        </div>
                    ))
                }

                <div className='w-100'>
                    <button className="btn btn-link fw-bold" onClick={handleNavigate}>
                        Aggiungi Codici
                        <FontAwesomeIcon icon="fa-regular fa-chevron-right" className="ms-2 text-primary" />
                    </button>
                </div>

            </div>
        </div>
    );
}