import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components/page';
import { Button, PasswordField } from "../../ui";
import { getAllUrlParams } from "../../libs";
import { giftService } from "../../store/services";

export function AlbumGift() {
    const navigate = useNavigate();
    const { id, title } = getAllUrlParams();
    const [email, setEmail] = React.useState('');
    const [errorCode, setErrorCode] = React.useState(null);
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [gifts, setGifts] = React.useState(undefined);

    useEffect(() => {
        giftService.get().then((res) => {
            setGifts(res);
        });
    }, []);
    const albumIsGifted = gifts && gifts.length > 0 && gifts.filter((gift) => gift.albumId === id).length > 0;

    const handleSendGift = () => {
        const emailLowerCaseWithoutSpace = email.toLowerCase().replace(/\s/g, '');
        giftService.post(id, emailLowerCaseWithoutSpace).then((res) => {
            if (res.status) {
                return res.json();
            }
            setShowSuccess(true);
        }).then((res) => {
            setErrorCode(res.errorCode);
        });

    };

    const handleCancelGift = () => {
        giftService.remove(id).then((res) => {
            if(res.message) {
                setGifts(gifts.filter((gift) => gift.albumId !== id));
                setShowSuccess(false);
            } else {
                setErrorCode(3);
            }
        });
    }

    const goToAlbumList = () => {
        navigate('/album-list');
    }

    if(albumIsGifted) {
        return <Page title={title} visibleBottomNavigation={false}>
            <div className="container mt-1">
                <div className="text-success mt-2 fw-bold text-center">
                    Questo album è già stato regalato ed è in attesa di essere accettato!
                </div>
                <Button otherClass={"mt-2"} onClick={goToAlbumList}>
                    Torna ai tuoi album
                </Button>
                <Button otherClass={"mt-2 btn-danger"} onClick={handleCancelGift}>
                    Annulla regalo
                </Button>
            </div>
        </Page>
    }
    

    if(showSuccess) {
        return <Page title={title} visibleBottomNavigation={false}>
            <div className="container mt-1">
                <div className="text-success mt-2 fw-bold text-center">
                    Album inviato con successo in attesa di essere accettato!
                </div>
                <Button otherClass={"mt-2"} onClick={goToAlbumList}>
                    Torna ai tuoi album
                </Button>
                <Button otherClass={"mt-2 btn-danger"} onClick={handleCancelGift}>
                    Annulla regalo
                </Button>
            </div>
        </Page>
    }

    return (
        <Page title={title} visibleBottomNavigation={false}>
            <div className="container mt-1 text-center">
                <p>
                    Puoi regalare questo album ad un amico. Se è già inscritto a Kangury riceverà una notifica, altrimenti un'email con le istruzioni per registrarsi e ricevere il regalo.
                </p>
                <p>
                    Una volta che il regalo verrà accettato, l'album verrà trasferito al nuovo utente e non sarà più disponibile nel tuo account.
                </p>
                <input type="text" className="form-control mt-3 rounded-5" placeholder="Inserisci l'email del destinatario" value={email} onChange={(e) => setEmail(e.target.value)} />
                {errorCode && <div className="text-danger mt-2 fw-bold text-center">
                    {translateErrorCode(errorCode)}
                </div>}
                <Button otherClass={"mt-2"} onClick={handleSendGift}>
                    Invia album come regalo
                </Button>
            </div>
        </Page>
    );
}

const translateErrorCode = (errorCode) => {
    switch (errorCode) {
        case 1:
            return 'Password non corretta';
        case 2:
            return 'Email non trovata, controlla che sia corretta o che l\'utente sia registrato a Kangury';
        default:
            return 'Errore server, riprova più tardi o, se si ripete, contatta l\'assistenza';
    }
}