import React from 'react';
import { ImgWithPlaceholder, PlaceholdersLoaderGif } from '../../../ui';
import { isContainerMd } from '../../../libs';

export function ListSelectionsQuantity({ list, image }) {
    const [isContainerMdValue, setIsContainerMdValue] = React.useState(isContainerMd());

  // add useeffect with listener on resize
  React.useEffect(() => {
    const handleResize = () => {
      setIsContainerMdValue(isContainerMd());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    return (
        <div className={`h-100 row ${isContainerMdValue ? 'p-3' : ''}`}>
            <div className='col-12 col-md-6 d-flex align-items-center'>
                {image &&
                    <ImgWithPlaceholder src={image} className={`img-fluid w-100 pe-none`} placeholder={PlaceholdersLoaderGif.loader_800_440}
                        alt="..." unselectable="on" />}
            </div>

            <div className={`col-12 col-md-6 d-flex align-items-center ${isContainerMdValue ? 'h-100 overflow-auto' : ''}`}>
                <div className={`bg-white rounded-5 rounded-bottom z-1 p-4 position-relative flex-grow-1 ${isContainerMdValue ? 'border h-100' : 'mt-n5 shadow-solo-top border-top'} `}>
                    <ul className={`list-group overflow-auto ${isContainerMdValue ? 'h-100' : ''}`}>
                        {list?.map((item, index) => (
                            <li className={`list-group-item border-0 p-0 text-dark ${index % 2 ? "list-group-item-secondary" : ""}`} key={index}>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}