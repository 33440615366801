import React, { useEffect } from 'react';
import { Page } from '../../components';
import { CardDiscount } from '../../components/card-discount';
import { useSelector, useDispatch } from 'react-redux';
import { discountService } from '../../store/services';

export function DiscountCodeList() {
    const [discountCodesList, setDiscountCodesList] = React.useState(null);
    const { general } = useSelector(state => state.mainData);

    React.useEffect(() => {
        if (!general) return;
        discountService.getAll(general.application)
            .then(data => {
                setDiscountCodesList(data);
            })
            .catch(err => {
                console.error(err);
            });
    }, [general])

    return (
        <Page visibleBottomNavigation={false}
            title={"Codici Sconto Disponibili"}>
            {discountCodesList
                && discountCodesList.map((discount, index) => {
                    return (
                        <CardDiscount
                            key={index}
                            title={discount.description}
                            code={discount.code}
                            shareAndCopy={true}
                            dateLimit={discount.expiresAt}
                            minItemTotal={discount.minItemTotal}
                        />
                    )
                })}
        </Page>
    ); 
}