import { cartConstants } from "../actions";
import { localDB } from "../cache";

const initialState = {
  products: [],
};

const cartData = (state = initialState, action) => {
  let draft = {
    ...state,
  };
  switch (action.type) {
    case cartConstants.ADD:
      /* window.ga.gtag('event', 'add_in_cart', {
        productId: product.productId,
        title: product.title,
        images: product.imagesList?.length
      }) */
      
      if (action.oldId) {
        // remove the old product updated
        draft.products = draft.products.filter(
          (product) => product.id !== action.oldId
        );
      }
      if (action.product.processingType === 1) {
        let isPresent = false;
        draft.products.every((product) => {
          if (product.productId === action.product.productId) {
            isPresent = true;
            product.imagesList = [...product.imagesList, ...action.product.imagesList];
            product.totalImages += action.product.totalImages;
            return false;
          }
          return true;
        });
        if (!isPresent) {
          // Add product for first element in array
          draft.products.unshift(action.product);
        }
      }
      else
        draft.products.unshift(action.product);

      localDB.set("cart", draft.products);
      break;
    case cartConstants.REMOVE:
      // Remove the product of a specific index
      draft.products = draft.products.filter(
        (product, index) => index !== action.indexProduct
      );
      localDB.set("cart", draft.products);
      break;
    case cartConstants.REMOVE_ALL:
      // Remove all products
      draft.products = [];
      draft.discount = null;
      localDB.set("cart", draft.products);
      break;
    case cartConstants.ADD_COUNT:
      draft.products = draft.products.map((product, index) => {
        if (index === action.indexProduct) {
          product.countInCart++;
        }
        return product;
      });
      localDB.set("cart", draft.products);
      break;
    case cartConstants.REMOVE_COUNT:
      draft.products = draft.products.map((product, index) => {
        if (index === action.indexProduct) {
          product.countInCart--;
        }
        return product;
      });
      localDB.set("cart", draft.products);
      break;
    case cartConstants.GET:
      draft.products = action.products || [];
      draft.isReady = true;
      break;
    case cartConstants.APPLY_DISCOUNT:
      draft.discount = action.discount;
      break;
    case cartConstants.CLEAN_DISCOUNT:
      draft.discount = null;
      break;
    case cartConstants.UPDATE_CART_IMAGE:
      draft.products = draft.products.map((product) => {
        if (product.imageCartProp?.id === action.idImage) {
          product.image = action.newUrl;
        }
        return product;
      });
      localDB.set("cart", draft.products);
      break;
    case cartConstants.UPDATE_PRODUCT_PRICE:
      draft.products = draft.products.map((product) => {
        if (product.productId === action.productId) {
          product.prices = action.prices;
        }
        return product;
      });
      localDB.set("cart", draft.products);
      break;
    default:
      break;
  }
  return draft;
}

export default cartData;
