import imageCompression from 'browser-image-compression';

export const imageReduced = async (file) => {
    const options = {
        maxSizeMB: 0.1, // Maximum size in MB
        maxWidthOrHeight: 430, // Maximum width or height
        useWebWorker: true, // Use web worker for faster compression
    };

    try {
        const compressedFile = await imageCompression(file, options);
        return compressedFile;
    } catch (error) {
        console.error('Error compressing file:', error);
        return file; // Return the original file if compression fails
    }
};

export const getVideoSnapshot = async (file) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        video.preload = 'metadata';

        video.onloadedmetadata = () => {
            // Set the canvas dimensions to match the video
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Seek to the 1-second mark
            video.currentTime = 1;
        };

        video.onseeked = () => {
            // Draw the video frame to the canvas
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Convert the canvas to a Blob
            canvas.toBlob((blob) => {
                // Create a new file from the Blob
                const snapshotFile = new File([blob], `${file.name}-snapshot.jpg`, { type: 'image/jpeg' });
                resolve(snapshotFile);
            }, 'image/jpeg');
        };

        video.onerror = (error) => {
            reject(error);
        };

        // Create a URL for the video file and set it as the video source
        const videoURL = URL.createObjectURL(file);
        video.src = videoURL;
    });
}