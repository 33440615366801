import React from 'react';
import { Page, CardDiscount } from '../../components';
import { Button, ToastDanger } from '../../ui';
import { useSelector, useDispatch } from 'react-redux';
import { discountService } from '../../store/services';
import { cartActions, userActions } from '../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllUrlParams, applyDiscounts } from '../../libs';

export function DiscountCode() {
    const dispatch = useDispatch();
    const [code, setCode] = React.useState("");
    const [showToast, setShowToast] = React.useState(false);
    const { general } = useSelector(state => state.mainData);
    const { discount } = useSelector(state => state.cartData);
    const [discounts, setDiscounts] = React.useState([]);
    const { totalPrice, courierPrice } = getAllUrlParams();
    const [toastMinimumPrice, setToastMinimumPrice] = React.useState(undefined);
    const _totalPrice = React.useMemo(() => parseFloat(totalPrice), [totalPrice]);
    const _courierPrice = React.useMemo(() => parseFloat(courierPrice), [courierPrice]);

    const [discountCodesList, setDiscountCodesList] = React.useState(null);

    React.useEffect(() => {
        if (!general) return;
        discountService.getAll(general.application)
            .then(data => {
                setDiscountCodesList(data);
            })
            .catch(err => {
                console.error(err);
            });
    }, [general])

    React.useEffect(() => {
        if (discount)
            setDiscounts(discount);
    }, [discount]);

    React.useEffect(() => {
        dispatch(userActions.getInfo());
    }, [dispatch]);

    const handleApplyCode = (codePassed = null) => {
        const _code = codePassed || code;
        if (_code) {
            discountService.get(_code, general.application)
                .then(data => {
                    if (data.message) {
                        setShowToast(false);
                        setToastMinimumPrice(undefined);
                        if (discounts.length === 0) {
                            const findDiscount = discountCodesList.find(d => d.code === _code);
                            findDiscount.credits = parseFloat(findDiscount.credits);
                            setDiscounts([findDiscount]);
                            dispatch(cartActions.applyDiscount([findDiscount]));
                            setCode('');
                        }
                        else
                            setShowToast(true);
                    }
                    else
                        setShowToast(true);
                })
                .catch(err => {
                    setShowToast(true);
                });
        }
    }

    const handleRemoveDiscount = (pin) => {
        const newDiscountsApplied = discounts.filter(discount => discount.code !== pin);
        dispatch(cartActions.applyDiscount(newDiscountsApplied));
        setDiscounts(newDiscountsApplied);
    }

    const [totalDiscounts, showedPriceDiscounted] = React.useMemo(() => {
        const totalPriceWithoutDiscounts = _totalPrice;
        const resultsDiscounts = applyDiscounts(discounts, _totalPrice);
        const totalPriceWithDiscounts = resultsDiscounts.total;

        return [totalPriceWithoutDiscounts - totalPriceWithDiscounts, totalPriceWithDiscounts];
    }, [_totalPrice, discounts]);

    return (
        <Page visibleBottomNavigation={false}
            title={"Codici Sconto"}>
            <div className="container p-3">
                <p>Hai un codice sconto? Inseriscilo nel campo sottostante e premi "Applica Codice"</p>
                <input type="text" placeholder="Inserisci Codice Sconto" className='w-100 form-control rounded-5 mt-3' value={code} onChange={(e) => {
                    setCode(e.target.value)
                    setShowToast(false)
                    setToastMinimumPrice(undefined)
                }} />
                <Button onClick={() => handleApplyCode()} otherClass={`mt-3 p-1 ${showToast ? 'mb-2' : 'mb-5'}`} disabled={!code}>
                    Applica Codice
                </Button>
                {showToast && <div className='mb-4'>
                    <ToastDanger>
                        {discounts.length === 1
                            ? "Puoi applicare un solo codice sconto"
                            : "Il codice inserito non è valido"}
                    </ToastDanger>
                </div>}
                <div className='fw-bold fs-4 mb-3'>Sconti Applicati:</div>
                {
                    discounts.map((discount, index) => {
                        return <div className={`d-flex justify-content-between mb-2 border-bottom position-relative pb-2`} key={index}>
                            <div>
                                <div className='fw-bold mb-2'>{discount.title}</div>
                                <div className=''>{discount.code}</div>
                            </div>
                            <div>
                                <button className='btn btn-link text-danger' onClick={() => handleRemoveDiscount(discount.code)}>
                                    <FontAwesomeIcon icon="fa-regular fa-circle-xmark" size="2x" />
                                </button>
                            </div>
                        </div>
                    })
                }
                <div className="d-flex justify-content-between mb-2">
                    <div>Totale Sconti:</div>
                    <div className='fw-bold text-primary'>{totalDiscounts.toFixed(2).replace(".", ",")} €</div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>Totale Ordine:</div>
                    <div className='fw-bold'>{showedPriceDiscounted.toFixed(2).replace(".", ",")} €</div>
                </div>
                {discountCodesList &&
                    <div className='fw-bold fs-4 mt-5'>Codici Sconto Disponibili:</div>}

                {discountCodesList
                    && discountCodesList.map((discount, index) => {
                        return (
                            <CardDiscount
                                key={index}
                                title={discount.description}
                                code={discount.code}
                                dateLimit={discount.expiresAt}
                                minItemTotal={discount.minItemTotal}
                                shareAndCopy={false}
                                applyCode={handleApplyCode} />
                        )
                    })}
            </div>
        </Page>
    );
}