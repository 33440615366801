import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function VideoWithPlaceholder({ src, className, controls = "", onClick, autoPlay = "", isCurrent, isCarousel = false, id }) {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            if (isCurrent) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }, [isCurrent]);

    const containerDiv = isCarousel ? 'd-flex justify-content-center align-items-center h-100' : 'position-relative';

    return (
        <div className={containerDiv} onClick={onClick} id={id}>
            <video
                ref={videoRef}
                className={className}
                controls={controls}
                autoPlay={autoPlay}
                preload="auto"
            >
                <source type="video/mp4" src={src} />
            </video>
            {!isCarousel && <FontAwesomeIcon icon="fa-solid fa-circle-play"
                className="position-absolute top-50 start-50 translate-middle bg-primary text-tertiary rounded-circle cursor-pointer z-1000" size='2x'
            />}
        </div>
    );
}