import React, { useEffect } from 'react';
import { Page } from '../../components';
import { getAllUrlParams } from '../../libs';
import { useDispatch } from 'react-redux';
import { orderService } from '../../store/services';

export function Pay() {
    const { orderId } = getAllUrlParams();
    const dispatch = useDispatch();
    const [payUrl] = React.useState(null);

    useEffect(() => {
        if (orderId) {
            orderService.getPayUrl(orderId).then(
                (data) => {
                    // setPayUrl(data.payUrl);
                    // navigate our of the app to the application url
                    // open data.payUrl in new tab
                    window.location.href = data.payUrl;
                    
                },
                (error) => {
                    console.error(error);
                }
            )
        }
    }, [dispatch, orderId]);

    return (
        <Page visibleBottomNavigation={false}
            loading={payUrl === null}
            title={`Pagamento`}>
        </Page>
    );
}