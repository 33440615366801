import React, { useEffect } from 'react';
import { Page } from '../../components';
import { getAllUrlParams } from '../../libs';

const faqs = [
  {
    id: 'faq1',
    question: 'Come funziona il vostro prodotto?',
    answer: 'Le nostre smartphoto ti permettono, semplicemente sfiorandole con lo smartphone, di accedere ad una memoria cloud associata che sarà tua per sempre, senza costi ricorrenti. Potrai conservare i tuoi ricordi migliori senza limitarti alle sole foto; condividerle con i tuoi amici ed averle sempre con te sulla nostra app.'
  },
  {
    id: 'faq2',
    question: 'Quanto spazio di archiviazione ho a disposizione?',
    answer: 'Ogni foto include 500 MB di spazio di archiviazione nel cloud. Questo spazio è destinato ai file multimediali associati (foto, video, audio). Non è richiesto alcun abbonamento: paghi solo al momento dell\'acquisto della foto.'
  },
  {
    id: 'faq3',
    question: 'Posso cambiare il contenuto associato al tag RFID?',
    answer: 'Sì, puoi aggiornare i contenuti associati al tag RFID in qualsiasi momento direttamente dall\'app Kangoury. Ti basterà accedere alla sezione "Gestisci Foto", selezionare la foto desiderata e caricare nuovi file.'
  },
  {
    id: 'faq4',
    question: 'Posso trasferire il contenuto associato a un’altra foto?',
    answer: 'No, lo spazio cloud è legato unicamente alla foto acquistata e non può essere trasferito ad altre foto. Tuttavia, puoi eliminare i file precedenti e caricare nuovi contenuti.'
  },
  {
    id: 'faq5',
    question: 'Il tag RFID non funziona. Cosa posso fare?',
    answer: <div>Se il tag RFID non viene rilevato: Assicurati che il telefono sia abilitato per l\'NFC. Puoi verificare nelle impostazioni del dispositivo. Controlla che il tag non sia stato danneggiato fisicamente. Se il problema persiste, contattaci all\'indirizzo: <a href="mailto:support@kangury.it">support@kangury.it</a>.</div>
  },
  {
    id: 'faq6',
    question: 'Posso utilizzare qualsiasi smartphone per leggere il tag RFID?',
    answer: 'La maggior parte degli smartphone moderni supporta la tecnologia NFC, ma ti consigliamo di verificare nelle specifiche del tuo dispositivo se l\'NFC è abilitato.'
  },
  {
    id: 'faq7',
    question: 'Cosa succede se perdo la foto?',
    answer: 'Se perdi la foto, il contenuto associato rimane al sicuro nel cloud, non sarà più accessibile tramite il tag RFID, ma rimarrà visualizzabile all\'interno della nostra app. Potrai sempre riordinare la medesima foto ed associarci il medesimo contenuto multimediale.'
  },
  {
    id: 'faq8',
    question: 'Il contenuto archiviato è protetto?',
    answer: 'Sì, i tuoi file sono protetti con tecnologie avanzate di crittografia e accessibili solo a te tramite l’account con cui hai effettuato l’acquisto o ai contatti ai quali condividi il link generato dall’app Kangury.'
  },
  {
    id: 'faq9',
    question: 'Come posso verificare quanto spazio ho utilizzato?',
    answer: 'Nell’app Kangury, accedi alla sezione "Gestisci Foto" e seleziona la foto desiderata. Qui troverai un indicatore dello spazio utilizzato e di quello rimanente.'
  },
  {
    id: 'faq10',
    question: 'Posso aumentare lo spazio di archiviazione per una foto?',
    answer: 'Al momento, lo spazio dedicato di 500 MB per ogni foto non è espandibile. Puoi però sottoscrivere uno dei nostri piani per ottenere spazio di archiviazione aggiuntivo da suddividere fra i tuoi vari album.'
  },
  {
    id: 'faq11',
    question: 'Cosa accade se elimino un file dal cloud?',
    answer: 'Eliminare un file dal cloud libera spazio, permettendoti di caricare nuovi contenuti. Ti ricordiamo che una volta eliminato un file, non sarà più recuperabile.'
  },
  {
    id: 'faq12',
    question: 'È possibile condividere i contenuti archiviati con altri?',
    answer: 'Sì, puoi generare un link condivisibile direttamente dall’app Kangury per consentire ad altri di visualizzare i contenuti archiviati.'
  },
  {
    id: 'faq13',
    question: 'Posso richiedere un rimborso se non sono soddisfatto?',
    answer: <div>Sì, offriamo una politica di "Soddisfatti o rimborsati" valida per 30 giorni dall\'acquisto. Contattaci a <a href="mailto:support@kangury.it">support@kangury.it</a> per ulteriori dettagli.'</div>
  },
  {
    id: 'faq14',
    question: 'I file caricati rimarranno nel cloud per sempre?',
    answer: 'Sì, una volta acquistata la foto, il tuo spazio di archiviazione cloud rimarrà attivo senza limiti di tempo e senza costi aggiuntivi.'
  },
  {
    id: 'faq15',
    question: 'Posso caricare file di qualsiasi formato?',
    answer: 'Il sistema supporta la maggior parte dei formati multimediali comuni (es. JPG, PNG, MP4, MP3). Se un file non è supportato, riceverai un messaggio di errore.'
  },
  {
    id: 'faq16',
    question: 'Nessuna domanda soddisfa le mie esigenze. Come posso contattarvi?',
    answer: <div>Puoi contattarci alla seguente mail: <a href="mailto:support@kangury.it">support@kangury.it</a>. Il nostro team sarà lieto di rispondere a tutte le tue domande.</div>
  }
];

export function Assistance() {
  const { contacts } = getAllUrlParams();
  const isLastAccordionOpen = contacts === 'true';

  useEffect(() => {
    // scroll to the last accordion if the contacts parameter is present
    const lastAccordion = document.getElementById('collapsefaq16');
    if (isLastAccordionOpen && lastAccordion) {
      lastAccordion.scrollIntoView();
    }
  }, [isLastAccordionOpen]);

  return (
    <Page title="Assistenza" visibleBottomNavigation={false}>
      <div className="accordion border-start border-end border-2 rounded-3 overflow-hidden m-3" id="accordion">
        {faqs.map((faq, index) => (
          <div key={faq.id} className="accordion-item rounded-0 border-start-0 border-end-0">
            <h2 className="accordion-header" id={`heading${faq.id}`}>
              <button
                className={`accordion-button ${isLastAccordionOpen && index === faqs.length - 1 ? '' : 'collapsed'} fw-bold`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${faq.id}`}
                aria-expanded={isLastAccordionOpen && index === faqs.length - 1 ? 'true' : 'false'}
                aria-controls={`collapse${faq.id}`}
              >
                {faq.question}
              </button>
            </h2>
            <div
              id={`collapse${faq.id}`}
              className={`accordion-collapse collapse ${isLastAccordionOpen && index === faqs.length - 1 ? 'show' : ''}`}
              aria-labelledby={`heading${faq.id}`}
              data-bs-parent="#accordion"
            >
              <div className="accordion-body">
                {faq.answer}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Page>
  );
}