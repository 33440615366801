import React, { useEffect } from "react";
import { Page } from '../../components/page';
import { Button, ToastInfo } from "../../ui";
import { useNavigate } from "react-router-dom";
import { albumService } from "../../store/services";
import { getAllUrlParams } from "../../libs";
import { Modals } from "./modals";

export function AlbumSettings() {
    const { id } = getAllUrlParams();
    const navigate = useNavigate();
    const [data, setData] = React.useState(undefined);
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [showToast, setShowToast] = React.useState(false);
    const [messageToast, setMessageToast] = React.useState('');
    const [showNotAccessError, setShowNotAccessError] = React.useState(false);

    useEffect(() => {
        if (!data) {
            albumService.get(id).then((res) => {
                if (res.status === 403) {
                    setShowNotAccessError(true);
                    return;
                }
                setData(res);
            });
        }
    }, [data, id]);

    const confirmRemoveAlbum = () => {
        albumService.deleteAlbum(id).then((res) => {
            if (res.status === 204) {
                navigate('/album-list');
            } else {
                setPasswordError(true);
            }
        });
    }

    const confirmDisassociateAlbum = () => {
        albumService.disassociateAlbum(id).then((res) => {
            if (res.message) {
                setShowToast(true);
                setMessageToast('Album disassociato con successo');
                setData({ ...data, nfc: null });
            }
        });
    }

    if (showNotAccessError) {
        return <Page title="Errore">
            <div className="container mt-2">
                Non hai accesso alle impostazioni di questo album
            </div>
        </Page>
    }

    return (
        <Page title={data ? data.name : "-"}
            loading={data === undefined}
            visibleBottomNavigation={false}>

            <div className="container mt-2">
                <div className="w-100 text-center mt-4">
                    Smartphoto associate: {data && data.nfc ? 1 : 0}
                </div>
                {data && data.nfc && <Button otherClass={"p-1 btn-secondary mt-1"} otherProps={{ 'data-bs-toggle': "modal", 'data-bs-target': "#disassociateAlbum" }}>
                    Disassocia Album
                </Button>}

                <Button otherClass={"p-1 btn-danger mt-3"} otherProps={{ 'data-bs-toggle': "modal", 'data-bs-target': "#removeAlbum" }}>
                    Elimina Album
                </Button>
            </div>
            {showToast && <ToastInfo children={messageToast} warningImageFormat={true} />}

            {
                data &&
                <Modals
                    confirmRemoveAlbum={confirmRemoveAlbum}
                    confirmDisassociateAlbum={confirmDisassociateAlbum}
                    password={password}
                    onChangePassword={(e) => setPassword(e.target.value)}
                    passwordError={passwordError}
                />
            }
        </Page>
    );
}