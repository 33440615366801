import React, { useEffect } from "react";
import { Page } from '../../components/page';
import { Button, CardGift } from "../../ui";
import { giftService } from "../../store/services";
import { formatDate } from "../../libs";
import { useNavigate } from 'react-router-dom';

export function GiftList() {
    const navigate = useNavigate();
    const [gifts, setGifts] = React.useState(undefined);
    const [acceptedAllGifts, setAcceptedAllGifts] = React.useState(false);

    const handleAcceptGift = (id) => {
        giftService.put(id).then((res) => {
            if (res.message) {
                const newGift = gifts.filter((gift) => gift.albumId !== id);
                setGifts(newGift);
                if (newGift.length === 0) {
                    setAcceptedAllGifts(true);
                }
            }
        });
    };

    const handleRejectGift = (id) => {
        giftService.remove(id).then((res) => {
            if (res.message) {
                const newGift = gifts.filter((gift) => gift.albumId !== id);
                setGifts(newGift);
                if (newGift.length === 0) {
                    setAcceptedAllGifts(true);
                }
            }
        });
    }

    const handleGoBackToAlbums = () => {
        navigate('/album-list');
    }

    useEffect(() => {
        if (!gifts) {
            giftService.get().then((res) => {
                if (res.length > 0) {
                    const giftWithUserInReceiver = res.filter(gift => gift.receiverEmail === localStorage.getItem('email'));
                    setGifts(giftWithUserInReceiver);
                }
            });
        }

    }, [gifts]);

    if (acceptedAllGifts) {
        return (
            <Page title={"Regali in attesa"} visibleBottomNavigation={false}>
                <div className="p-3 h-100">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="text-center">Non ci sono altri regali in attesa</h3>
                            <Button onClick={handleGoBackToAlbums} className="w-100" color="primary">
                                Torna ai tuoi album
                            </Button>
                        </div>
                    </div>
                </div>
            </Page >
        );
    }

    return (
        <Page loading={!gifts} title={"Regali in attesa"} visibleBottomNavigation={false}>
            <div className="p-3 h-100">
                <div className="row">
                    {gifts && gifts.map((gift) => (
                        <div className="col-12 col-md-6 col-lg-4 mb-4" key={gift.id}>
                            <CardGift
                                handleAccept={() => handleAcceptGift(gift.albumId)}
                                handleReject={() => handleRejectGift(gift.albumId)}
                                title={gift.albumName}
                                sender={gift.senderEmail}
                                creationDate={formatDate(gift.dateCreatedGift)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Page >
    );
}