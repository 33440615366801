import React from 'react';
import { Page } from '../../components';

export function PrivacyPolicy() {
    return (
        <Page title={'Informativa sulla Privacy'}
            visibleBottomNavigation={false}>
            <div className='container d-flex align-items-start justify-content-center'>
                <div className='card p-4 shadow-lg mb-4 mt-4'>
                    <h2 className='text-center mb-4'>Tutela della Privacy</h2>

                    <p>
                        Kangury si impegna costantemente per tutelare la privacy dei suoi clienti. Questo documento le permetterà di conoscere la nostra politica sulla privacy per capire come le sue informazioni personali vengono gestite quando utilizza i nostri servizi e per consentirle, se del caso, di prestare un consenso al trattamento dei suoi dati personali espresso e consapevole nelle sezioni dei nostri siti ove viene richiesto di fornire i dati personali. Le ricordiamo che nelle varie sezioni dei siti web (di seguito "Sito") ove raccogliamo i suoi dati personali dovrà utilizzare come riferimento il presente documento anche ai sensi dell'art. 13 del Regolamento EU 2016/679 (di seguito: "Regolamento") per sua necessaria presa visione prima della fornitura dei dati richiesti. Le informazioni ed i dati da lei forniti o altrimenti acquisiti nell'ambito della navigazione sul Sito e nell'utilizzo dei servizi di Kangury, (di seguito "Servizi"), saranno oggetto di trattamento nel rispetto delle disposizioni del Regolamento e degli obblighi di riservatezza che ispirano l'attività di Kangury.

                    </p>
                    <p>
                        Secondo le norme del Regolamento, i trattamenti effettuati da Kangury saranno improntati ai principi di liceità, correttezza, trasparenza, limitazione delle finalità e della conservazione, minimizzazione dei dati, esattezza, integrità e riservatezza.

                    </p>

                    <h2>1. Titolare</h2>

                    <p>
                        Il Titolare dei trattamenti svolti attraverso il Sito è Kangury come sopra definito. Il Titolare è a disposizione per qualunque informazione inerente il trattamento dei dati personali di Kangury, tra cui l'elenco dei responsabili che trattano dati. È possibile contattare il Titolare scrivendo a:<a href="mailto:info@kangury.it">info@kangury.it</a>
                    </p>

                    <h2>2. I dati personali oggetto del trattamento</h2>

                    <p>
                        Per trattamento di dati personali intendiamo qualsiasi operazione o insieme di operazioni, compiute con o senza l'ausilio di processi automatizzati e applicate a dati personali o insiemi di dati personali, come la raccolta, la registrazione, l'organizzazione, la strutturazione, la conservazione, l'adattamento o la modifica, l'estrazione, la consultazione, l'uso, la comunicazione mediante trasmissione, diffusione o qualsiasi altra forma di messa a disposizione, il raffronto o l'interconnessione, la limitazione, la cancellazione o la distruzione. La informiamo che i dati personali oggetto di trattamento saranno costituiti da un identificativo come il nome, l'indirizzo email, un numero di identificazione, dati relativi all'ubicazione, un identificativo online, gli acquisti effettuati, e altri dati idonei a renderla identificato/a o identificabile (di seguito solo "Dati Personali"). in particolare, i Dati Personali trattati attraverso il Sito sono i seguenti:
                    </p>

                    <h4>
                        a. Dati di navigazione
                    </h4>

                    <p>
                        I sistemi informatici e le procedure software preposte al funzionamento del Sito acquisiscono, nel corso del loro normale esercizio, alcuni Dati Personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al Sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, etc.) ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'utente. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'uso del Sito e per controllarne il corretto funzionamento, per identificare anomalie e/o abusi, e vengono cancellati immediatamente dopo l'elaborazione. I dati potrebbero essere utilizzati per l'accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito o di terzi: salva questa eventualità, allo stato i dati sui contatti web non persistono per più di trenta giorni.
                    </p>

                    <h4>
                        b. Dati forniti volontariamente dall'interessato
                    </h4>

                    <p>
                        Nell'utilizzo dei Servizi è presente il trattamento dei suoi Dati Personali che possono includere: Nome, Cognome, Indirizzo, mail e numero di telefono che possono essere utilizzati per identificarLa.
                    </p>

                    <p>
                        Potrebbe inoltre verificarsi un trattamento dei Dati Personali di terzi soggetti da lei inviati al gestore del Servizio. Rispetto a questa ultima ipotesi, lei si pone come autonomo titolare del trattamento, assumendosi tutti gli obblighi e le responsabilità di legge. In tal senso, lei conferisce sul punto la più ampia manleva rispetto ad ogni contestazione, pretesa, richiesta di risarcimento del danno da trattamento, etc. che dovesse pervenire a Kangury da terzi soggetti i cui Dati Personali siano stati trattati attraverso il suo utilizzo dei Servizi in violazione delle norme sulla tutela dei dati personali applicabili.
                    </p>

                    <p>
                        Il nostro partner Digitalpix si impegna al trattamento delle foto e delle immagini da Lei inviate per la stampa nel rispetto della normativa e per il solo tempo necessario alla fornitura del servizio richiesto. Tutte le immagini sono cancellate dai nostri sistemi e supporti di memorizzazione in modo automatico e non sono previsti backup che possano mantenerne delle copie. Il tempo di permanenza delle foto/immagini sui nostri sistemi è di 50 giorni dalla data di evasione/spedizione dell'ordine.
                    </p>

                    <p>
                        Kangury si impegna al trattamento dei file da Lei inviate nel rispetto della normativa e per il solo tempo necessario alla fornitura del servizio richiesto. Tutte i file vengono mantenuti nei nostri sistemi finchè l'utente non sceglie di rimuoverle e non vengono condivisi con nessun servizio terzo.
                    </p>

                    <h2>c. Cookie utilizzati - Definizioni, caratteristiche e applicazione della normativa</h2>

                    <p>
                        I cookie sono piccoli file di testo che i siti visitati dall'utente inviano e registrano sul suo computer o dispositivo mobile, per essere poi ritrasmessi agli stessi siti alla successiva visita. Proprio grazie ai cookie un sito ricorda le azioni e preferenze dell'utente (come, ad esempio, i dati di login, la lingua prescelta, le dimensioni dei caratteri, altre impostazioni di visualizzazione, ecc.) in modo che non debbano essere indicate nuovamente quando l'utente torni a visitare detto sito o navighi da una pagina all'altra di esso. I cookie, quindi, sono usati per eseguire autenticazioni informatiche, monitoraggio di sessioni e memorizzazione di informazioni riguardanti le attività degli utenti che accedono ad un sito e possono contenere anche un codice identificativo unico che consente di tenere traccia della navigazione dell'utente all'interno del sito stesso per finalità statistiche o pubblicitarie. Nel corso della navigazione su un sito, l'utente può ricevere sul suo computer o dispositivo mobile anche cookie di siti o di web server diversi da quello che sta visitando (c.d. cookie di "terze parti"). Alcune operazioni non potrebbero essere compiute senza l'uso dei cookie, che in certi casi sono quindi tecnicamente necessari per lo stesso funzionamento del sito.
                    </p>

                    <p>
                        Esistono vari tipi di cookie, a seconda delle loro caratteristiche e funzioni, e questi possono rimanere nel computer o dispositivo mobile dell'utente per periodi di tempo diversi: c.d. cookie di sessione, che viene automaticamente cancellato alla chiusura del browser; c.d. cookie persistenti, che permangono sull'apparecchiatura dell'utente fino ad una scadenza prestabilita.
                    </p>

                    <p>
                        In base alla normativa vigente in Italia, per l'utilizzo dei cookie non sempre è richiesto un espresso consenso dell'utente. In particolare, non richiedono tale consenso i "cookie tecnici", cioè quelli utilizzati al solo fine di effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria per erogare un servizio esplicitamente richiesto dall'utente. Si tratta, in altre parole, di cookie indispensabili per il funzionamento del sito o necessari per eseguire attività richieste dall'utente.
                    </p>

                    <p>
                        Al momento Kangury fa utilizzo di soli cookie tecnici per gestire la comunicazione con il backend e privi di dati personali.
                    </p>

                    <h2>3. Gestione dei File</h2>

                    <p>
                        S3 è un servizio clould offerto da Amazon Web Service e che permette l'archiviazione di file di vario tipo.
                    </p>

                    <p>
                        I file da Lei inviati vengono salvati nel servizio di archiviazione cloud S3, garantendo la protezione e la sicurezza dei dati. È importante sapere che, una volta entrati in un album e scaricati i file per la visualizzazione da parte dell'utente, i file sono accessibili tramite un URL unico, dalla lunghezza e complessità che lo rendono impossibile da indovinare da parte di terzi, e il quale ha una durata limitata di un'ora. Dopo tale periodo, il link scadrà automaticamente e non sarà più possibile accedere ai file tramite l'URL fornito.
                    </p>

                    <p>
                        Nel caso in cui decida di condividere l'URL dei suoi file con terzi, la responsabilità di tale condivisione è esclusivamente a Suo carico. Lei si impegna a garantire che tale condivisione avvenga nel rispetto della privacy e della normativa vigente, assumendosi ogni responsabilità derivante da un uso improprio o non autorizzato dei file da parte di chiunque riceva l'URL.
                    </p>

                    <p>
                        L'utente può scegliere di eliminare i file in qualsiasi momento, liberando spazio per nuovi contenuti. Tuttavia, una volta eliminato un file, non sarà più possibile recuperarlo. Tale file non sarà conservato nei nostri archivi.
                    </p>

                    <h4>
                        a. Dove sono collocati geograficamente i file salvati in S3?
                    </h4>

                    <p>
                        I file sono salvati in datacenter di Amazon Web Services (AWS) situati in Europa, a Stoccolma, garantendo la conformità con la normativa europea sulla protezione dei dati personali.
                    </p>

                    <h4>
                        b. Come sono salvati i file su S3
                    </h4>

                    <p>
                        I file sono prima resi anonimi generando un nome casuale e rimuovendone l'estensione prima di salvarli su S3. Per garantire un veloce download sono salvati in chiaro su S3 ma un utente può accedere soltanto ai propri file tramite il proprio token di autenticazione. A meno che non si scelga di rendere un album pubblico, in tal caso chiunque entri in possesso dell'url dell'album potra accedere e visualizzare i file.
                    </p>

                    <p>
                        Gli amministratori di sistema si impegnano a mantenere la massima privacy e a non accedere direttamente ai file a meno che non esplicitamente richiesto dagli utenti per problemi tecnici.
                    </p>

                    <h4>
                        4. Conservazione dei Dati
                    </h4>

                    <p>
                        Tutti i dati e file inseriti dall'utente vengono conservati per tutto il tempo in cui l'utente utilizzerà Kangury. L'utente ha il diritto, in qualsiasi momento, di eliminare il proprio account attraverso l'applicazione. Tramite tale procedura qualsiasi dato e file in conservato nei nostri sistemi verrà rimosso all'istante, salvo problemi tecnici che ne possano ritardare la rimozione.
                    </p>

                    <p>
                        In caso di problemi tecnici che impediscano la rimozione dei dati, l'utente può contattare il supporto tecnico all'indirizzo <a href="mailto:support@kangury.it">support@kangury.it</a>
                    </p>

                    <h2>
                        5. Futuri aggiornamenti
                    </h2>

                    <p>
                        Kangury è un progetto in continua evoluzione e si riserva il diritto di apportare modifiche a questa informativa sulla privacy. In caso di modifiche, Kangury si impegna a informare gli utenti attraverso i canali di comunicazione ufficiali o richiedendone di nuovo l'accettazione.
                    </p>
                </div>
            </div>
        </Page>
    )
}