import React from "react";
import { Modal } from "../../ui";

export const Modals = ({ name, onChangeNameAlbum, confirmChangeName }) => {
    return (
        <>
            <Modal
                title={"Cambia nome all'album"}
                text={<input type="text" className="form-control" placeholder="Nome album" value={name} onChange={onChangeNameAlbum} />}
                primaryText="Salva"
                secondaryText="Annulla"
                handleConfirm={confirmChangeName}
                primaryColor="btn-primary"
                id="changeName"
            />
        </>
    )
}