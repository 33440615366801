import React from "react";
import { Page } from '../../components/page';
import { Modal, ToastDanger } from "../../ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllUrlParams } from "../../libs";
import { albumService } from "../../store/services/album.service";

const AlbumPrivacyValues = {
    PRIVATE: "0",
    PUBLIC: "1",
}

export function AlbumPrivacy() {
    const { id, privacy, title } = getAllUrlParams();
    const [shareCode, setShareCode] = React.useState();
    const [isPublic, setIsPublic] = React.useState(privacy === AlbumPrivacyValues.PUBLIC);
    const [modalData, setModalData] = React.useState({
        title: '',
        text: '',
    });
    const [showToast, setShowToast] = React.useState(false);

    const enablePublic = () => {
        setIsPublic(!isPublic);
        const newPrivacy = isPublic ? AlbumPrivacyValues.PRIVATE : AlbumPrivacyValues.PUBLIC;
        albumService.changePrivacy(id, newPrivacy).then((res) => {
            if (res.status) {
                setShowToast(true);
                setIsPublic(isPublic);
            }
        });
        // change privacy value in url without add history
        const url = new URL(window.location.href);
        url.searchParams.set('privacy', newPrivacy);
        window.history.replaceState({}, '', url);
    };

    const enableShareCode = (value) => {
        // generate random code of 10 characters alphanumeric in capital letters
        if (!isPublic) {
            const code = Math.random().toString(36).substring(2, 12).toUpperCase();
            setShareCode(code);
        }
        else {
            setShareCode('');
        }
        setIsPublic(!isPublic);
    };

    const handleCopyCode = () => {
        navigator.clipboard.writeText(shareCode);
    };

    const users = Array.from({ length: 20 }, (_, index) => `user${index + 1}@example.com`);

    const handleHelpShareAll = () => {
        setModalData({
            title: 'Rendi pubblico',
            text: 'Se attivi questa opzione, il tuo album, e le foto in esso contenute, sarà visibile a tutti gli utenti con cui condividerai il link dell\'album o se l\'utente avvicina lo smartphone alla tua smart foto.',
        });
    }

    const handleHelpShareCode = () => {
        setModalData({
            title: 'Codice condivisione',
            text: 'Se attivi questa opzione, il tuo album sarà visibile solo a chi inserirà il codice di condivisione. Il codice di condivisione verrà generato nuovo, e in maniera casuale, ogni volta che attivi l\'opzione.',
        });
    }

    const handleHelpShareUsers = () => {
        setModalData({
            title: 'Utenti Autorizzati a Visualizzare',
            text: 'Inserisci l\'email dell\'utente che vuoi autorizzare a visualizzare il tuo album. L\'utente dovrà essere registrato a Kangury per poter essere autorizzato.',
        });
    }

    return (
        <Page title={title}
            visibleBottomNavigation={false}>
            <div className="container mt-1 fs-4">
                <h2 className="text-center">
                    Privacy Album
                    </h2>
                <div className="d-flex justify-content-between align-items-center form-check form-switch p-1">
                    <div>
                        <span>Rendi pubblico</span>
                        <FontAwesomeIcon icon="fa-regular fa-circle-question" className="bg-primary rounded-5 cursor-pointer ms-3" onClick={handleHelpShareAll} data-bs-toggle="modal" data-bs-target="#privacyModal" />
                    </div>
                    <div>
                        <input className="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault" onClick={enablePublic} value={isPublic} checked={isPublic} />
                    </div>
                </div>

                {/* <div className="d-flex justify-content-between align-items-center form-check form-switch p-1 mt-2">
                    <div>
                        <span>Codice condivisione</span>
                        <FontAwesomeIcon icon="fa-regular fa-circle-question" className="bg-primary rounded-5 cursor-pointer ms-3" onClick={handleHelpShareCode} data-bs-toggle="modal" data-bs-target="#privacyModal" />
                    </div>
                    <div>
                        <input className="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault" onClick={enableShareCode} value={isPublic} />
                    </div>
                </div>
                <SharedCodeField
                    value={shareCode}
                    handleClick={handleCopyCode} /> */}
            </div>
            {/* <div className="border-top border-2 mt-3" />
            <div className="container mt-3 text-center h-75">
                <p className="fs-3">
                    Utenti Autorizzati a Visualizzare
                    <FontAwesomeIcon icon="fa-regular fa-circle-question" className="bg-primary rounded-5 cursor-pointer ms-3" onClick={handleHelpShareUsers}  data-bs-toggle="modal" data-bs-target="#privacyModal" />
                </p>
                <div className="border border-2 mt-3 overflow-auto h-75">
                    {users.map((user, index) => (
                        <div className="d-flex justify-content-between align-items-center border-bottom border-1 p-2" key={index}>
                            <p className="m-0">mario.rossi@gmail.com</p>
                            <FontAwesomeIcon icon="fa-regular fa-minus" className="bg-danger p-2 rounded-5 cursor-pointer" />
                        </div>
                    ))}
                </div>
                <div className="d-flex mt-3">
                    <input type="email" className="form-control flex-grow-1"
                     placeholder="Aggiungi utente (email)" />
                    <Button otherClass={"ms-2 w-auto"}>
                        <FontAwesomeIcon icon="fa-regular fa-plus" className="bg-primary p-2 rounded-5 cursor-pointer" />
                    </Button>
                </div>
            </div> */}
            <Modal
                title={modalData.title}
                text={modalData.text}
                secondaryText="Chiudi"
                id="privacyModal"
            />
            {showToast && <ToastDanger>
                <p>Errore, non è stato possibile cambiare la privacy dell'album</p>
            </ToastDanger>}
        </Page>
    );
}