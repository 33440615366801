import { common } from "./common.service"

export const discountService = {
    get,
    getAll
}


function get(code) {
    let url = `discount/validate?code=${code}`;
    return common.fetchAPI({ url, method: "GET", token: true });
}

function getAll(application) {
    const userIsLogged = !!localStorage.getItem('token');
    let url = `discount?application=${application}`;
    if (userIsLogged) {
        return common.fetchAPI({ url, method: "GET", token: true });
    } else {
        return common.fetchAPI({ url, method: "GET" });
    }
}