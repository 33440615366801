import React from 'react';
import { Page } from '../../components';

export function ShippingCosts() {
    return (
        <Page title={'Spese di spedizione'}
            visibleBottomNavigation={false}>
            <div className='container d-flex align-items-start justify-content-center'>
                <div className='card p-4 shadow-lg mb-4 mt-4'>
                    <h1 className='text-center mb-4'>Spese di spedizione</h1>
                    <p>
                        In Italia la spedizione è sempre gratuita.
                    </p>
                </div>
            </div>
        </Page>
    )
}