import React, { useEffect } from "react";
import { Page } from '../../components/page';
import { Button, AlbumList as AlbumListUI, CardSimple } from "../../ui";
import { useNavigate } from 'react-router-dom';
import { albumService, giftService } from "../../store/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortAlbum } from "./sort";

export function AlbumList() {
    const [albums, setAlbums] = React.useState(undefined)
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [gifts, setGifts] = React.useState(undefined);

    // check if there is gifts where user is not the senderEmail
    const giftInPending = gifts && gifts.filter && gifts.filter(gift => gift.senderEmail !== localStorage.getItem('email')).length > 0;

    const handleOpen = (id) => {
        navigate('/album?id=' + id);
    };

    useEffect(() => {
        if (!albums) {
            albumService.getList().then((res) => {
                if (!res.status && res.length > 0)
                    setAlbums(res);
                else
                    setAlbums([]);
            });
        }
        if (!gifts) {
            giftService.get().then((res) => {
                setGifts(res);
            });
        }
    }, [albums, gifts]);

    const goToHome = () => {
        navigate('/');
    }

    const goToLogin = () => {
        navigate('/login');
    }

    const goToGifts = () => {
        navigate('/gift-list');
    }

    if (!token) {
        return <Page logoOnTop={true}>
            <div className="text-center w-100 h-100 d-flex flex-column justify-content-center align-items-center p-4">
                <h2>Devi effettuare il login per accedere a questa pagina</h2>
                <Button otherClass="btn-primary p-1 mt-3" onClick={goToLogin}>
                    <span className="text-tertiary  fs-5">
                        Vai al login
                    </span>
                </Button>
            </div>
        </Page>
    }

    return (
        <Page loading={!albums} logoOnTop={true}>
            <div className="p-3 h-100">
                {giftInPending && <CardSimple otherClass={"mb-4"}>
                    <div className="d-flex justify-content-between align-items-center">
                        <FontAwesomeIcon icon={`fa-regular fa-gift`} />
                        <div className="text-center">
                            Qualcuno ti ha regalato un album!
                        </div>
                        <FontAwesomeIcon icon={`fa-regular fa-gift`} />
                    </div>
                    <div className="w-50 mx-auto mt-2">
                        <Button otherClass="btn-primary p-0" onClick={goToGifts}>
                            <span className="text-tertiary fs-6">
                                Vai ai Regali
                            </span>
                        </Button>
                    </div>
                </CardSimple>}
                <SortAlbum setAlbums={setAlbums} albums={albums} />
                <AlbumListUI albums={albums} handleAction={handleOpen} gifts={gifts} />
                {/* <CardSimple
                    otherClass={"bg-primary shadow mt-4"}
                    children={<><div className="d-flex justify-content-between">
                        <p className="fw-bold">
                            Memoria premium usata:
                        </p>
                        <p>
                            0Gb / 10Gb
                        </p>
                    </div>
                        <ProgressBarMemory percentage={0} />
                    </>} >
                </CardSimple> */}
                {albums && albums.length === 0 && <div className='text-center w-100 h-75 d-flex flex-column justify-content-center align-items-center p-4'>
                    <FontAwesomeIcon icon="fa-regular fa-face-sad-cry" className='mb-1' size='3x' />
                    <div className='mb-1 fs-4 fw-bold'>Non hai ancora nessun album</div>
                    <div className='mb-3 fs-6'>Crea una smartphoto della tua ultima vacanza o regalala ad un amico per festeggiare un evento speciale trascorso insieme!</div>
                    <Button otherClass="btn-primary p-1" onClick={goToHome}>
                        <span className="text-tertiary  fs-5">
                            <FontAwesomeIcon icon="fa-regular fa-cart-plus" className='me-2' />
                            Aggiungi un prodotto
                        </span>
                    </Button>
                </div>}
            </div>
        </Page >
    );
}