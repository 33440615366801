import React from 'react'
import { PlaceholdersLoaderGif, ImgWithPlaceholder, Button, ProgressBarMemory, VideoWithPlaceholder } from '..'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function CardAlbum({ handleAction, title, files, memoryUsed, cover, coverType, memoryTotal = 500, associate = false, creationDate, nfc, sentToGift }) {

    return (
        <div className="card p-3 shadow">
            <div className="row g-0">
                <h5 className={`card-title fw-bold ${sentToGift ? 'col-8' : 'col-9'}`}>
                    {title}
                </h5>
                {
                    sentToGift && <div className="col-1">
                        <FontAwesomeIcon icon={`fa-regular fa-hand-holding-box`} />
                    </div>
                }
                <div className="col-3">
                    <Button otherClass={"p-0"} otherProps={{ "data-bs-toggle": "modal", "data-bs-target": "#confirmAssociate" }}
                        onClick={handleAction}>
                        {associate ? 'Associa' : 'Apri'}
                    </Button>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-4 d-flex justify-content-center align-items-center cursor-pointer position-relative" onClick={handleAction}>
                    <ImgWithPlaceholder src={cover}
                        className="card-img-top pe-none"
                        alt={'image'}
                        unselectable="on"
                        placeholder={PlaceholdersLoaderGif.loader_1200_1200}
                        type={coverType}
                        isVideo={coverType && coverType.includes('video')}
                    />
                </div>
                <div className="col-8 position-relative">
                    <div className="card-body">
                        {files && <p className="card-text mb-0">
                            <span className="fw-bold me-1">Elementi:</span>
                            {files}
                        </p>}
                        {creationDate && <p className="card-text mb-0">
                            <span className="fw-bold me-1">Creazione:</span>
                            {creationDate}
                        </p>}
                        <p className="card-text mb-0">
                            <span className='fw-bold'> Associato:</span> {nfc ? 'Si' : 'No'}
                        </p>
                        {memoryUsed >= 0 &&
                            <><p className="card-text mb-0">
                                <span className='fw-bold'> Memoria:</span> {memoryUsed.toFixed(2)}/{memoryTotal}Mb
                            </p>
                                <ProgressBarMemory percentage={memoryUsed / memoryTotal * 100} />
                            </>}
                    </div>
                </div>

            </div>
        </div>
    );
}